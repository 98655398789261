<template>
  <div class="new">
    <i class="fas fa-star" />
    New
  </div>
</template>

<style lang="scss">
  .new {
    padding: 6px;
    background-color: darkorange;
    color: #fff;
    border-radius: 3px;
    display: inline;
    font-size: small;

    .fa-star {
      color: yellow;
    }
  }
</style>
